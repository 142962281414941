/*global jQuery */
/*jshint multistr:true browser:true */
/*!
* FitVids 1.0
*
* Copyright 2011, Chris Coyier - http://css-tricks.com + Dave Rupert - http://daverupert.com
* Credit to Thierry Koblentz - http://www.alistapart.com/articles/creating-intrinsic-ratios-for-video/
* Released under the WTFPL license - http://sam.zoy.org/wtfpl/
*
* Date: Thu Sept 01 18:00:00 2011 -0500
*/

(function( $ ){

  "use strict";

  $.fn.fitVids = function( options ) {
    var settings = {
      customSelector: null
    };

    var div = document.createElement('div'),
        ref = document.getElementsByTagName('base')[0] || document.getElementsByTagName('script')[0];

    div.className = 'fit-vids-style';

    // JG EDIT - had to convert multi-line string to concat'd string
    // to avoid uglification issues.
    var str = '&shy;<style>';
        str += '.fluid-width-video-wrapper {';
        str += 'width: 100%;';
        str += 'position: relative;';
        str += 'padding: 0;';
        str += '}';

        str += '.fluid-width-video-wrapper iframe,';
        str += '.fluid-width-video-wrapper object,';
        str += '.fluid-width-video-wrapper embed {';
        str += 'position: absolute;';
        str += 'top: 0;';
        str += 'left: 0;';
        str += 'width: 100%;';
        str += 'height: 100%;';
        str += '}';
        str += '</style>';

    div.innerHTML = str;

    ref.parentNode.insertBefore(div,ref);

    if ( options ) {
      $.extend( settings, options );
    }

    // JG Edit: Add www.bloomberg.com to the list of sources
    return this.each(function(){
      var selectors = [
        "iframe[src*='player.vimeo.com']",
        "iframe[src*='www.youtube.com']",
        "iframe[src*='www.youtube-nocookie.com']",
        "iframe[src*='www.kickstarter.com']",
        "iframe[src*='www.bloomberg.com']",
        "object",
        "embed"
      ];

      if (settings.customSelector) {
        selectors.push(settings.customSelector);
      }

      var $allVideos = $(this).find(selectors.join(','));

      $allVideos.each(function(){
        var $this = $(this);
        if (this.tagName.toLowerCase() === 'embed' && $this.parent('object').length || $this.parent('.fluid-width-video-wrapper').length) { return; }
        var height = ( this.tagName.toLowerCase() === 'object' || ($this.attr('height') && !isNaN(parseInt($this.attr('height'), 10))) ) ? parseInt($this.attr('height'), 10) : $this.height(),
            width = !isNaN(parseInt($this.attr('width'), 10)) ? parseInt($this.attr('width'), 10) : $this.width(),
            aspectRatio = height / width;
        // JG Edit: We will not be able to accurately calculate the height in all cases.
        // To protect against this, we will assume a MIMUMUM .562 H/W ratio.
        if (aspectRatio < 0.562) {
            aspectRatio = 0.562;
        }
        if(!$this.attr('id')){
          var videoID = 'fitvid' + Math.floor(Math.random()*999999);
          $this.attr('id', videoID);
        }
        $this.wrap('<div class="fluid-width-video-wrapper"></div>').parent('.fluid-width-video-wrapper').css('padding-top', (aspectRatio * 100)+"%");
        $this.removeAttr('height').removeAttr('width');
      });
    });
  };
})( jQuery );
